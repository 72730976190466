@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css");
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url("https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css");
@import url("https://cdn.jsdelivr.net/npm/destyle.css@1.0.15/destyle.css");
@import url("react-lazy-load-image-component/src/effects/blur.css");

html {
  scroll-behavior: smooth;
  color: #1b1b1b;
  font-size: 62.5%;
  font-family: NanumBarunGothic, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

html,
body {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

img {
  pointer-events: none;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

/** Pretendard */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

/** Noto Serif */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR&display=swap');

@font-face {
  font-family: "NanumBarunGothic";
  src: url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/NanumBarunGothic.ttf") format("truetype"), url("https://cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SangSangFlowerRoad";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.0/SangSangFlowerRoad.woff") format("woff"),
    url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/SangSangFlowerRoad.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HaenamFont";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2202-2@1.0/HaenamFont.woff") format("woff"), url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/HaenamFont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KyoboHandwriting2020A";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2112@1.0/KyoboHandwriting2020A.woff") format("woff"),
    url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/KyoboHandwriting2020A.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquareEB";
  src: url("https://cdn.jsdelivr.net/npm/@kfonts/nanum-square@0.1.3/NanumSquareR.woff") format("woff"), url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/NanumSquareR.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KoPubDotumMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/KoPubDotumMedium.woff") format("woff"),
    url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/KoPubDotumMedium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GongGothicMedium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/GongGothicMedium.woff") format("woff"),
    url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/GongGothicMedium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSerifKR-Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NotoSerifKR.woff") format("woff"), url("https://d3fc8mvhyp5uqo.cloudfront.net/fonts/NotoSerifKR-Regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
